@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&family=VT323&display=swap');
.App{
  text-align: center;
}
/* unvisited link */
a:link {
  color: white;
}

/* visited link */
a:visited {
  color: white;
}

/* mouse over link */
a:hover {
  color: rgb(131, 80, 203);
}

/* selected link */
a:active {
  color: rgb(131, 80, 203);
}
img {
  max-width: 100%;
}
nav {
  text-align: center;
  background: black;
  font-family: 'Roboto';
  font-size: calc(10px + 4vmin);
  color: white;
  margin-top: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  max-width: 960px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  mix-blend-mode: overlay;
  white-space: pre-wrap;
  /* font-size: calc(10px + 2vmin); */
  color: white;
  font-family: Roboto;
  font-size: 30vmin;
  margin: 5rem 0rem 0;
  font-weight: 100;
}
.Music-body {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  mix-blend-mode: overlay;
  white-space: pre-wrap;
  color: white;
  font-family: Roboto;
  margin: 8rem 0rem 0;
  font-size: 18vmin;
  font-weight: 100;
}
.Connect-body {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  mix-blend-mode: overlay;
  white-space: pre-wrap;
  color: white;
  font-family: Roboto;
  margin: 12rem 0rem 0;
  font-size: 18vmin;
  font-weight: 100;
}
.email-link {
  justify-content: center;
  align-items: center;
  mix-blend-mode: overlay;
  white-space: pre-wrap;
  color: white;
  font-family: Roboto;
  font-size: 9vmin;
  font-weight: 100;
}
.product {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
.offers, .products {
  display: flex;
  gap: 20px;
}
.App-footer {
  text-align: center;
  background: black;
  font-family: Roboto;
  font-size: calc(10px + 4vmin);
  color: white;
  margin-top: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.bgVideo {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index:-1;
}